/*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
  */
.simulator_table table {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: calc(0.5em + 0.5vw);
  text-align: center;
  background-color: transparent;
}
.simulator_table tbody tr :first-child {
  background-color: #e89a37;
  color: white;
}
.simulator_table tbody tr td span {
  color: black !important;
  background-color: transparent !important;
}
.simulator_table tbody tr :nth-child(2) {
  background-color: rgba(232, 154, 55, 0.5);
  color: black !important;
}
.simulator_table thead tr :first-child {
  background-color: #e89a37;
  color: white;
}

.simulator_table thead tr :nth-child(2) {
  background-color: rgba(232, 154, 55, 0.5);
  color: black !important;
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    /*top: -9999px;
    left: -9999px;
    */
  }

  tr {
    margin: 0 0 1rem 0;
  }

  tr:nth-child(odd) {
    background: #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #e89a37;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  /*
	Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
	*/
  .sim_table td:nth-of-type(1):before {
    text-align: left;
    font-weight: bold;
    content: "Plazo";
    padding-top: 10px;
  }
  
  .sim_table td:nth-of-type(2):before {
    text-align: left;
    content: "Hoy";
    padding-top: 10px;
  }
  .sim_table td:nth-of-type(3):before {
    text-align: left;
    content: "1 Mes";
    padding-top: 10px;
  }
  .sim_table td:nth-of-type(4):before {
    text-align: left;
    content: "2 Meses";
    padding-top: 10px;
  }
  .sim_table td:nth-of-type(5):before {
    text-align: left;
    content: "3 Meses";
    padding-top: 10px;
  }
  .sim_table td:nth-of-type(6):before {
    text-align: left;
    content: "6 Meses";
    padding-top: 10px;
  }
  .sim_table td:nth-of-type(7):before {
    content: "1 Año";
    text-align: left;
    padding-top: 10px;
  }

  /** rescate */
  .rescate-table td:nth-of-type(1):before {
    text-align: left;
    font-weight: bold;
    content: "Seguro";
    padding-top: 10px;
  }
  .rescate-table td:nth-of-type(2):before {
    text-align: left;
    font-weight: bold;
    content: "Inicio vigencia seguro";
    padding-top: 10px;
  }
  .rescate-table td:nth-of-type(3):before {
    text-align: left;
    font-weight: bold;
    content: "Prima mensual";
    padding-top: 10px;
  }
  .rescate-table td:nth-of-type(4):before {
    text-align: left;
    font-weight: bold;
    content: "Cantidad primas pagadas";
    padding-top: 10px;
  }
  .rescate-table td:nth-of-type(5):before {
    text-align: left;
    font-weight: bold;
    content: "Monto primas pagadas";
    padding-top: 10px;
  }
  .rescate-table td:nth-of-type(6):before {
    text-align: left;
    font-weight: bold;
    content: "Disponible para retiro";
    padding-top: 10px;
  }
}
